<template>
    <b-container fluid class="pl-0 pr-0">
        <b-row
            no-gutters
            align-v="start"
            align-h="between"
            v-if="
                filterEnabled || autoupdateEnabled ||
                tableButtonsEnabled ||
                iconButtonsEnabled ||
                exportable
            "
        >
            <b-col
                :class="[
                    iconButtonsEnabled == false
                        ? 'col-xs-12 col-md-12 col-lg-8 col-xl-9'
                        : 'col-xs-12 col-md-12 col-lg-auto col-xl-auto',
                ]"
            >
                <template
                    v-if="
                        simpleFilters != undefined && simpleFilters.length > 0
                    "
                >
                    <div
                        :class="[
                            simpleFilters.length > 1 || iconButtonsEnabled
                                ? 'flex-wrap flex-md-grow-1 align-items-start d-lg-inline-flex'
                                : '',
                        ]"
                    >
                        <div
                            v-for="filter in simpleFilters"
                            :key="filter.key"
                            class="mb-1 mr-2"
                            :class="[
                                filter.class != undefined && filter.class != ''
                                    ? filter.class
                                    : '',
                            ]"
                        >
                            <template v-if="filter.type == 'text'">
                                <b-input-group>
                                    <b-input-group-prepend is-text>
                                        <i
                                            class="feather icon-search text-primary"
                                        ></i>
                                    </b-input-group-prepend>
                                    <b-form-input
                                        v-b-tooltip.hover
                                        :title="
                                            filter.placeholder != undefined
                                                ? filter.placeholder
                                                : 'Search ' + filter.key
                                        "
                                        @change="refreshTable()"
                                        v-model="filter.search"
                                        type="text"
                                        :placeholder="
                                            filter.placeholder != undefined
                                                ? filter.placeholder
                                                : 'Search ' + filter.key
                                        "
                                    >
                                    </b-form-input>
                                </b-input-group>
                            </template>
                            <template
                                v-if="
                                    filter.type == 'select' ||
                                    filter.type == 'year'
                                "
                            >
                                <b-input-group>
                                    <b-input-group-prepend
                                        is-text
                                        v-if="filter.icon != undefined"
                                    >
                                        <i
                                            :class="filter.icon"
                                            class="text-primary"
                                        ></i>
                                    </b-input-group-prepend>
                                    <b-form-select
                                        @change="refreshTable()"
                                        v-model="filter.search"
                                        v-b-tooltip.hover
                                        :title="'Select option'"
                                    >
                                        <template #first>
                                            <template
                                                v-if="
                                                    filter.no_first_option ==
                                                        undefined ||
                                                    !filter.no_first_option
                                                "
                                            >
                                                <b-form-select-option
                                                    :value="null"
                                                >
                                                    {{
                                                        (filter.prefix_label ||
                                                            "") +
                                                        filter.default_label
                                                    }}
                                                </b-form-select-option>
                                            </template>
                                        </template>
                                        <b-form-select-option
                                            v-for="option in filter.options
                                                .values"
                                            :key="option[filter.options.key]"
                                            :value="option[filter.options.key]"
                                        >
                                            {{
                                                (filter.prefix_label || "") +
                                                option[filter.options.label]
                                            }}
                                        </b-form-select-option>
                                    </b-form-select>
                                </b-input-group>
                            </template>
                            <template v-if="filter.type == 'multiselect'">
                                <multiselect
                                    v-model="filter.search"
                                    class="w140"
                                    @select="refreshTable()"
                                    @remove="refreshTable()"
                                    :append-to-body="{ optionsZIndex: 1050 }"
                                    :options="filter.options.values"
                                    :track-by="filter.options.key"
                                    :label="filter.options.label"
                                    :searchable="true"
                                    :openDirection="
                                        !isEmptyValue(
                                            filter.options.openDirection
                                        )
                                            ? filter.options.openDirection
                                            : ''
                                    "
                                    :allow-empty="
                                        isEmptyValue(filter.empty)
                                            ? false
                                            : filter.empty
                                    "
                                    selectLabel=""
                                    deselectLabel=""
                                    :placeholder="
                                        !isEmptyValue(filter.empty) &&
                                        !isEmptyValue(filter.default_label)
                                            ? filter.default_label
                                            : 'Select option'
                                    "
                                    v-b-tooltip.hover
                                    :title="'Select option'"
                                >
                                    <template
                                        slot="singleLabel"
                                        slot-scope="props"
                                    >
                                        {{
                                            (filter.prefix_label || "") +
                                            props.option.name
                                        }}
                                        <b-badge
                                            class="ml-1 mr-2"
                                            :variant="props.option.theme"
                                            v-if="
                                                props.option.theme !=
                                                    undefined &&
                                                props.option.theme != ''
                                            "
                                        >
                                            {{
                                                props.option.name
                                                    .charAt(0)
                                                    .toUpperCase()
                                            }}
                                        </b-badge>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        {{
                                            (filter.prefix_label || "") +
                                            props.option.name
                                        }}
                                        <b-badge
                                            class="ml-1 mr-2"
                                            :variant="props.option.theme"
                                            v-if="
                                                props.option.theme !=
                                                    undefined &&
                                                props.option.theme != ''
                                            "
                                        >
                                            {{
                                                props.option.name
                                                    .charAt(0)
                                                    .toUpperCase()
                                            }}
                                        </b-badge>
                                    </template>
                                </multiselect>
                            </template>
                            <template v-if="filter.type == 'rangedate'">
                                <b-input-group
                                    v-b-tooltip.hover
                                    :title="
                                        filter.placeholder != undefined
                                            ? filter.placeholder
                                            : 'Select range date'
                                    "
                                >
                                    <b-input-group-prepend is-text>
                                        <i
                                            class="feather icon-calendar text-primary"
                                        ></i>
                                    </b-input-group-prepend>
                                    <flat-pickr
                                        v-model="filter.search"
                                        @on-close="refreshTable()"
                                        class="form-control"
                                        :config="cfgrangepicker"
                                        :placeholder="
                                            filter.placeholder != undefined
                                                ? filter.placeholder
                                                : 'Select range date'
                                        "
                                    >
                                    </flat-pickr>
                                </b-input-group>
                            </template>
                            <template v-if="filter.type == 'date'">
                                <b-input-group
                                    v-b-tooltip.hover
                                    :title="
                                        filter.placeholder != undefined
                                            ? filter.placeholder
                                            : 'Select date'
                                    "
                                >
                                    <b-input-group-prepend is-text>
                                        <i
                                            class="feather icon-calendar text-primary"
                                        ></i>
                                    </b-input-group-prepend>
                                    <flat-pickr
                                        v-model="filter.search"
                                        @on-close="refreshTable()"
                                        @on-change="refreshTable()"
                                        class="form-control"
                                        :config="cfgdatepicker"
                                        :placeholder="
                                            filter.placeholder != undefined
                                                ? filter.placeholder
                                                : 'Select date'
                                        "
                                    >
                                    </flat-pickr>
                                </b-input-group>
                            </template>
                            <template
                                v-if="
                                    simpleFilters.length > 1 &&
                                    filter.separator != undefined &&
                                    filter.separator
                                "
                            >
                                <hr class="hr-custom" />
                            </template>
                        </div>
                        <template
                            v-if="
                                simpleFilters.length > 1 &&
                                showResetSimpleFilters == true
                            "
                        >
                            <div class="mb-1 mr-2 align-self-center">
                                <b-button
                                    @click="resetSimpleFilters()"
                                    type="button"
                                    variant="link text-underline"
                                    v-b-tooltip.hover
                                    title="Remove filters"
                                >
                                    Remove filters
                                </b-button>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-if="autoupdateEnabled && autoupdatePosition=='top'">
                    <div class="mt-2 mb-2">
                        <b-button class="m-r-10" @click="toggleTimer()" :variant="(timer_enabled ? 'primary' : 'outline-primary')" :title="titleTimer">
                            <i class="feather icon-refresh-cw"></i><span class="p-l-10">Auto load data</span>
                        </b-button>
                        <b-button v-if="counter_enabled" variant="outline-primary">
                            <span class="m-r-10">Next update on {{ counterMinutes }}:{{ counterSeconds }}</span>
                        </b-button>
                    </div>
                </template>
            </b-col>
            <template v-if="!tableButtonsEnabled && iconButtonsEnabled">
                <b-col xs="12" md="12" lg="auto" xl="auto" class="text-right">
                    <template v-if="iconButtonsEnabled">
                        <b-button
                            :disabled="
                                !isEmptyValue(button.disabled)
                                    ? button.disabled
                                    : false
                            "
                            :class="button.class"
                            v-for="button in icon_buttons"
                            :key="button.key"
                            :variant="
                                button.variant != undefined
                                    ? button.variant
                                    : 'outline-primary'
                            "
                            @click="
                                button.clicknobus != undefined
                                    ? callToNoBusFunction(button.clicknobus)
                                    : callToFunction(
                                          button.click,
                                          button.params
                                      )
                            "
                        >
                            <template v-if="button.icon != undefined">
                                <b-img
                                    block
                                    center
                                    :src="button.icon"
                                    :title="button.label"
                                ></b-img>
                            </template>
                            {{ button.label }}
                        </b-button>
                    </template>
                </b-col>
            </template>
            <template v-if="tableButtonsEnabled || exportable">
                <b-col xl="3" lg="4" class="mb-1 text-right">
                    <template
                        v-if="tableButtonsEnabled && table_buttons.length == 1"
                    >
                        <b-button
                            :disabled="
                                !isEmptyValue(button.disabled)
                                    ? button.disabled
                                    : false
                            "
                            :class="button.class"
                            v-for="button in table_buttons"
                            :key="button.key"
                            :variant="
                                button.variant != undefined
                                    ? button.variant
                                    : 'outline-primary'
                            "
                            @click="
                                button.clicknobus != undefined
                                    ? callToNoBusFunction(button.clicknobus)
                                    : callToFunction(
                                          button.click,
                                          button.params
                                      )
                            "
                        >
                            <template v-if="button.icon != undefined">
                                <i :class="button.icon" class="mr-2"></i>
                            </template>
                            {{ button.label }}
                        </b-button>
                        <template v-if="exportable">
                            <download-excel
                                class="btn btn-outline-primary"
                                :data="itemdata"
                                :fields="exportableFields"
                                :header="exportableTitle"
                                :escapeCsv="!exportableCsv"
                                :type="(exportableCsv ? 'csv' : 'xls')"
                            >
                                <i class="ph-file-xls mr-2"></i>Export
                            </download-excel>
                        </template>
                    </template>
                    <template v-else-if="tableButtonsEnabled">
                        <b-button-group>
                            <b-dropdown
                                size="sm"
                                variant="outline-primary"
                                right
                                toggle-class="text-decoration-none ml-0"
                                no-caret
                            >
                                <template #button-content>
                                    <i class="feather icon-more-horizontal-"></i
                                    ><span class="sr-only">Actions</span>
                                </template>
                                <b-dropdown-item-button
                                    v-for="button in table_buttons"
                                    :key="button.key"
                                    v-on:click="
                                        button.clicknobus != undefined
                                            ? callToNoBusFunction(
                                                  button.clicknobus
                                              )
                                            : callToFunction(
                                                  button.click,
                                                  button.params
                                              )
                                    "
                                >
                                    <span :class="button.class">
                                        <template
                                            v-if="button.icon != undefined"
                                        >
                                            <i
                                                :class="button.icon"
                                                class="mr-2"
                                            ></i>
                                        </template>
                                        {{ button.label }}
                                    </span>
                                </b-dropdown-item-button>
                                <template v-if="exportable">
                                    <b-dropdown-item-button>
                                        <download-excel
                                            class="text-primary"
                                            :data="itemdata"
                                            :fields="exportableFields"
                                            :header="exportableTitle"
                                            :escapeCsv="!exportableCsv"
                                            :type="(exportableCsv ? 'csv' : 'xls')"
                                        >
                                            <i class="ph-file-xls mr-2"></i>
                                            Export
                                        </download-excel>
                                    </b-dropdown-item-button>
                                </template>
                            </b-dropdown>
                        </b-button-group>
                    </template>
                    <template v-else>
                        <download-excel
                            class="btn btn-outline-primary"
                            :data="itemdata"
                            :fields="exportableFields"
                            :header="exportableTitle"
                            :escapeCsv="!exportableCsv"
                            :type="(exportableCsv ? 'csv' : 'xls')"
                        >
                            <i class="ph-file-xls mr-2"></i>
                            Export
                        </download-excel>
                    </template>
                </b-col>
            </template>
            <template
                v-if="!visibleAdvancedFilterPanel &&  appliedFilters().length > 0"
            >
                <b-col cols="12" class="f-w-7 font-16"> Applied filters </b-col>
                <b-col cols="12" class="mt-2">
                    <div
                        class="align-items-start flex-wrap d-inline-flex button-list"
                    >
                        <div
                            v-for="appfilter in appliedFilters()"
                            :key="appfilter.key"
                            class="mb-1 mr-2"
                        >
                            <b-button
                                variant="outline-primary"
                                @click="removeFilter(appfilter)"
                                class="bg-light"
                                v-if="!isEmptyValue(appfilter.filtered_value)"
                            >
                                <span class="v-a-m">{{
                                    appfilter.filtered_value
                                }}</span
                                ><span class="v-a-m ml-3 font-18">X</span>
                            </b-button>
                        </div>
                    </div>
                </b-col>
            </template>
            <transition name="fade">
                <template
                    v-if="
                        advancedFilters != undefined &&
                        advancedFilters.length > 0
                    "
                >
                    <b-col
                        v-if="visibleAdvancedFilterPanel"
                        id="advancedFiltersPanel"
                        ref="advancedFiltersPanel"
                        cols="12"
                    >
                        <b-card
                            bg-variant="primary"
                            class="mt-2 card-adv-filter"
                            text-variant="white"
                        >
                            <b-card-text>
                                <b-row align-v="center" class="mt-3 mb-2">
                                    <b-col>
                                        <span
                                            class="font-20 f-w-6 font-secondary text-white"
                                            >Advanced filters</span
                                        >
                                    </b-col>
                                    <b-col class="text-right">
                                        <span
                                            class="f-w-7 font-12 pointer"
                                            @click="
                                                visibleAdvancedFilterPanel =
                                                    !visibleAdvancedFilterPanel
                                            "
                                            >Close</span
                                        >
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col
                                        md="6"
                                        v-for="filter in advancedFilters"
                                        :key="filter.key"
                                        class="mb-3"
                                    >
                                        <template v-if="(!isEmptyValue(filter.payload_function))">
                                            {{ callToFunction(filter.payload_function, 'no-params') }}
                                        </template>


                                        <template v-if="filter.type == 'text'">
                                            <b-input-group>
                                                <b-input-group-prepend is-text>
                                                    <i
                                                        class="feather icon-search text-primary"
                                                    ></i>
                                                </b-input-group-prepend>
                                                <b-form-input
                                                    @change="refreshTable()"
                                                    v-model="filter.search"
                                                    type="text"
                                                    :placeholder="
                                                        filter.placeholder !=
                                                        undefined
                                                            ? filter.placeholder
                                                            : 'Search ' +
                                                              filter.key
                                                    "
                                                >
                                                </b-form-input>
                                            </b-input-group>
                                        </template>
                                        <template
                                            v-if="
                                                filter.type == 'select' ||
                                                filter.type == 'year'
                                            "
                                        >
                                            <b-input-group>
                                                <b-input-group-prepend
                                                    is-text
                                                    v-if="
                                                        filter.icon != undefined
                                                    "
                                                >
                                                    <i
                                                        :class="filter.icon"
                                                        class="text-primary"
                                                    ></i>
                                                </b-input-group-prepend>
                                                <b-form-select
                                                    @change="refreshTable()"
                                                    v-model="filter.search"
                                                >
                                                    <template #first>
                                                        <template
                                                            v-if="
                                                                filter.no_first_option ==
                                                                    undefined ||
                                                                !filter.no_first_option
                                                            "
                                                        >
                                                            <b-form-select-option
                                                                :value="null"
                                                            >
                                                                {{
                                                                    (filter.prefix_label ||
                                                                        "") +
                                                                    filter.default_label
                                                                }}
                                                            </b-form-select-option>
                                                        </template>
                                                    </template>
                                                    <b-form-select-option
                                                        v-for="option in filter
                                                            .options.values"
                                                        :key="
                                                            option[
                                                                filter.options
                                                                    .key
                                                            ]
                                                        "
                                                        :value="
                                                            option[
                                                                filter.options
                                                                    .key
                                                            ]
                                                        "
                                                    >
                                                        {{
                                                            (filter.prefix_label ||
                                                                "") +
                                                            option[
                                                                filter.options
                                                                    .label
                                                            ]
                                                        }}
                                                    </b-form-select-option>
                                                </b-form-select>
                                            </b-input-group>
                                        </template>
                                        <template v-if="(filter.type == 'multiselect' && (isEmptyValue(filter.visible) || filter.visible == 'true'))">
                                            <multiselect
                                                v-model="filter.search"
                                                class="w140"
                                                @select="refreshTable()"
                                                @remove="refreshTable()"
                                                :append-to-body="{
                                                    optionsZIndex: 1050,
                                                }"
                                                :options="filter.options.values"
                                                :track-by="filter.options.key"
                                                :label="filter.options.label"
                                                :searchable="true"
                                                :openDirection="
                                                    !isEmptyValue(
                                                        filter.options
                                                            .openDirection
                                                    )
                                                        ? filter.options
                                                              .openDirection
                                                        : ''
                                                "
                                                :allow-empty="
                                                    isEmptyValue(filter.empty)
                                                        ? false
                                                        : filter.empty
                                                "
                                                :multiple="
                                                    filter.options.multiple
                                                "
                                                selectLabel=""
                                                deselectLabel=""
                                                :placeholder="
                                                    !isEmptyValue(
                                                        filter.empty
                                                    ) &&
                                                    !isEmptyValue(
                                                        filter.default_label
                                                    )
                                                        ? filter.default_label
                                                        : 'Select option'
                                                "
                                            >
                                                <template
                                                    slot="singleLabel"
                                                    slot-scope="props"
                                                >
                                                    {{
                                                        (filter.prefix_label ||
                                                            "") +
                                                        props.option.name
                                                    }}
                                                    <b-badge
                                                        class="ml-1 mr-2"
                                                        :variant="
                                                            props.option.theme
                                                        "
                                                        v-if="
                                                            props.option
                                                                .theme !=
                                                                undefined &&
                                                            props.option
                                                                .theme != ''
                                                        "
                                                    >
                                                        {{
                                                            props.option.name
                                                                .charAt(0)
                                                                .toUpperCase()
                                                        }}
                                                    </b-badge>
                                                </template>
                                                <template
                                                    slot="option"
                                                    slot-scope="props"
                                                >
                                                    {{
                                                        (filter.prefix_label ||
                                                            "") +
                                                        props.option.name
                                                    }}
                                                    <b-badge
                                                        class="ml-1 mr-2"
                                                        :variant="
                                                            props.option.theme
                                                        "
                                                        v-if="
                                                            props.option
                                                                .theme !=
                                                                undefined &&
                                                            props.option
                                                                .theme != ''
                                                        "
                                                    >
                                                        {{
                                                            props.option.name
                                                                .charAt(0)
                                                                .toUpperCase()
                                                        }}
                                                    </b-badge>
                                                </template>
                                            </multiselect>
                                        </template>
                                        <template
                                            v-if="filter.type == 'rangedate'"
                                        >
                                            <b-input-group>
                                                <b-input-group-prepend is-text>
                                                    <i
                                                        class="feather icon-calendar text-primary"
                                                    ></i>
                                                </b-input-group-prepend>
                                                <flat-pickr
                                                    v-model="filter.search"
                                                    @on-close="refreshTable()"
                                                    class="form-control"
                                                    :config="cfgrangepicker"
                                                    :placeholder="
                                                        filter.placeholder !=
                                                        undefined
                                                            ? filter.placeholder
                                                            : 'Select range date'
                                                    "
                                                >
                                                </flat-pickr>
                                            </b-input-group>
                                        </template>
                                        <template v-if="filter.type == 'date'">
                                            <b-input-group>
                                                <b-input-group-prepend is-text>
                                                    <i
                                                        class="feather icon-calendar text-primary"
                                                    ></i>
                                                </b-input-group-prepend>
                                                <flat-pickr
                                                    v-model="filter.search"
                                                    @on-close="refreshTable()"
                                                    @on-change="refreshTable()"
                                                    class="form-control"
                                                    :config="cfgdatepicker"
                                                    :placeholder="
                                                        filter.placeholder !=
                                                        undefined
                                                            ? filter.placeholder
                                                            : 'Select date'
                                                    "
                                                >
                                                </flat-pickr>
                                            </b-input-group>
                                        </template>
                                    </b-col>
                                </b-row>
                                <b-row align-v="center">
                                    <b-col
                                        cols="12"
                                        class="button-list-right text-right"
                                    >
                                        <b-button
                                            @click="resetAdvancedFilters()"
                                            type="button"
                                            variant="primary text-underline"
                                            v-b-tooltip.hover
                                            title="Remove filters"
                                        >
                                            Remove filters
                                        </b-button>
                                        <b-button
                                            @click="
                                                toggleAdvancedFilters();
                                                refreshTable();
                                            "
                                            type="button"
                                            variant="white"
                                            v-b-tooltip.hover
                                            title="Search"
                                            >Search</b-button
                                        >
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-card>
                    </b-col>
                </template>
            </transition>
        </b-row>
        <b-row
            v-if="
                ((filterEnabled || tableButtonsEnabled || autoupdateEnabled) &&
                    visible &&
                    separatorVisible) ||
                exportable
            "
        >
            <b-col cols="12"><hr class="hr-custom" /></b-col>
        </b-row>
        <b-overlay variant="transparent" :show="statusTable" class="mx-auto">
            <template #overlay>
                <div class="text-center">
                    <span class="loader_hm"></span>
                </div>
            </template>
            <b-table
                v-infinite-scroll="infiniteScroll ? onScroll : null"
                :infinite-scroll-disabled="statusTable"
                :infinite-scroll-distance="perPage"
                v-show="visible"
                :id="id"
                :ref="'bTable' + id"
                show-empty
                :empty-filtered-text="emptyFilteredText"
                :borderless="borderless"
                :bordered="bordered"
                :striped="striped"
                :empty-text="emptyText"
                :primary-key="primaryKey"
                :current-page="currentPage"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                sort-icon-left
                :hover="hover"
                :stacked="stacked"
                :sticky-header="stickyHeader"
                :responsive="responsive"
                :items="serverside ? myProvider : items"
                :fields="fields"
                :busy.sync="statusTable"
                :select-mode="selectmode"
                :selectable="selectable"
                :no-select-on-click="noSelectOnClick"
                :forceVisible="forceVisible"
                :separatorVisible="separatorVisible"
                :tbody-tr-attr="rowAttribute"
                :tbody-tr-class="rowClass"
                :thead-class="theadClass"
                :tbody-class="tbodyClass"
                @row-selected="onrowselected"
                :showResetSimpleFilters="showResetSimpleFilters"
                :foot-clone="footClone"
                :tfoot-tr-class="trClassFooter"
                :class="tbClass"
                :scroll-to-id="scrollToId"
            >
                <template #head()="data">
                    <slot name="headslot" v-bind:data="data">
                        <div class="text-nowrap">
                            {{ data.label }}
                        </div>
                    </slot>
                </template>
                <template #foot()="data">
                    <slot name="footslot" v-bind:data="data">
                        {{ data.label }}
                    </slot>
                </template>
                <template #cell()="data">
                    <slot name="cellslot" v-bind:data="data">
                        {{ data.value }}
                    </slot>
                </template>
            </b-table>
        </b-overlay>
        <b-row v-if="visible && separatorVisible">
            <b-col>
                <hr class="hr-custom" />
            </b-col>
        </b-row>
        <b-row
            align-v="center"
            v-if="!infiniteScroll && ((pagination && totalRows > 0 && perPage > 0 && !hidePagination) || autoupdateEnabled)"
        >
            <b-col sm="auto" md="auto" class="mb-1">
                <b-form-select
                    v-if="perPage > 0"
                    id="per-page-select-bottom"
                    v-model="perPage"
                    :options="pageOptions"
                >
                </b-form-select>
            </b-col>
            <b-col sm="auto" md="auto">
                <b-pagination
                    v-if="perPage > 0"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    prev-text="Prev"
                    next-text="Next"
                    class="mb-1"
                >
                    <template #page="{ page, active }">
                        <span v-bind:class="{ 'page-span-link': active }">
                            {{ page }}
                        </span>
                    </template>
                </b-pagination>
            </b-col>
            <b-col class="text-right" v-if="autoupdateEnabled && autoupdatePosition=='bottom'">
                <b-button v-if="counter_enabled" variant="outline-primary">
                    <span class="m-r-10">Next update on {{ counterMinutes }}:{{ counterSeconds }}</span>
                </b-button>
                <b-button class="m-l-10 m-r-075" @click="toggleTimer()" :variant="(timer_enabled ? 'primary' : 'outline-primary')" :title="titleTimer">
                    <i class="feather icon-refresh-cw"></i>
                </b-button>
            </b-col>
        </b-row>
        <b-row
            align-v="center"
            v-if="infiniteScroll && visibleLoadMore"
        >
            <b-col class="text-center">
                <b-button variant="secondary" class="b-r-4 btn-xw" @click="onScroll()">
                    <i class="font-14 ph ph-arrow-down m-r-10"></i>
                    More
                </b-button>
            </b-col>
        </b-row>
        <b-row v-if="!isEmptyValue(fields) && fields.length > 0 && hasLegends" class="mt-3">
            <b-col>
                <template v-for="column in fields" v-if="!isEmptyValue(column.legend)">
                    <span class="badge m-r-10" :class="column.legend_class">{{ column.legend }}</span>
                </template>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
/*import { TablePlugin, LayoutPlugin, PaginationPlugin, OverlayPlugin, FormSelectPlugin, FormInputPlugin, FormGroupPlugin,
    ButtonPlugin, InputGroupPlugin } from 'bootstrap-vue'
import VueFlatPickr from 'vue-flatpickr-component'

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

Vue.component('multiselect', require('vue-multiselect').default)

VueFlatPickr.watch.value = function value(newValue) {
    this.fp && this.fp.setDate(newValue)
}
import 'flatpickr/dist/flatpickr.css'
import 'flatpickr/dist/themes/airbnb.css'
*/

export default {
    name: "w-table",
    /*components: {
        TablePlugin, LayoutPlugin, PaginationPlugin, OverlayPlugin, FormSelectPlugin, FormInputPlugin, FormGroupPlugin, ButtonPlugin,
        InputGroupPlugin, VueFlatPickr
    },*/
    props: {
        pagination: {
            type: Boolean,
            default: true,
        },
        perPageDefault: {
            type: Number,
            default: 10,
        },
        hidePagination: {
            type: Boolean,
            default: false
        },
        currentPageDefault: {
            type: Number,
            default: 1,
        },
        pageOptionsDefault: {
            type: Array,
            default: function () {
                return [
                    { value: 5, text: "Show 5" },
                    { value: 10, text: "Show 10" },
                    { value: 25, text: "Show 25" },
                    { value: 50, text: "Show 50" },
                    { value: 100, text: "Show 100" },
                    { value: 500, text: "Show 500" },
                    { value: 0, text: "Show All" },
                ];
            },
        },
        totalRowsDefault: {
            type: Number,
            default: 0,
        },
        sortByDefault: {
            type: String,
            default: "",
        },
        sortDescDefault: {
            type: Boolean,
            default: false,
        },
        sortDirectionDefault: {
            type: String,
            default: "asc",
        },
        statusTableDefault: Boolean,
        id: {
            type: String,
            require: true,
        },
        emptyFilteredText: {
            type: String,
            default: "There are no records matching your request",
        },
        emptyText: {
            type: String,
            default: "There are no records to show",
        },
        primaryKey: String,
        stacked: {
            default: "lg",
        },
        fields: {
            type: Array,
            require: true,
        },
        filters: {
            type: Array,
            require: false,
            default: function () {
                return [];
            },
        },
        table_buttons: {
            type: Array,
            require: false,
            default: function () {
                return [];
            },
        },
        icon_buttons: {
            type: Array,
            require: false,
            default: function () {
                return [];
            },
        },
        items: {
            type: Array,
            require: false,
            default: function () {
                return [];
            },
        },
        serverside: {
            type: Boolean,
            default: false,
        },
        method: {
            type: String,
            default: "post",
        },
        url: {
            type: String,
            default: "",
        },
        otherparams: {
            type: Object,
            default: () => ({}),
        },
        otherfilters: {
            type: Object,
            default: () => ({}),
        },
        selectmode: {
            type: String,
            default: "single",
        },
        onrowselected: {
            type: Function,
            default: () => ({}),
        },
        selectable: {
            type: Boolean,
            default: false,
        },
        forceVisible: {
            type: Boolean,
            default: false,
        },
        noSelectOnClick: {
            type: Boolean,
            default: false,
        },
        separatorVisible: {
            type: Boolean,
            default: true,
        },
        callbackCloseAdvancedPanel: {
            type: String,
            default: "",
        },
        responsive: {
            type: Boolean,
            default: false,
        },
        stickyHeader: {
            default: false,
        },
        callbackProvider: {
            type: Function,
            default: null,
        },
        /*payloadFunction: {
            type: Function,
            default: null,
        },*/
        borderless: {
            type: Boolean,
            default: true,
        },
        bordered: {
            type: Boolean,
            default: false,
        },
        striped: {
            type: Boolean,
            default: false,
        },
        rowAttribute: {
            type: Function,
            default: () => ({}),
        },
        rowClass: {
            type: Function,
            default: () => ({}),
        },
        theadClass: {
            type: String,
            default: "",
        },
        tbodyClass: {
            type: String,
            default: "",
        },
        showResetSimpleFilters: {
            type: Boolean,
            default: false,
        },
        exportable: {
            type: Boolean,
            default: false,
        },
        exportableFields: {
            type: Object,
            default: () => ({}),
            require: false,
        },
        exportableTitle: {
            type: String,
            default: "",
        },
        exportableCsv: {
            type: Boolean,
            default: false,
        },
        footClone: {
            type: Boolean,
            default: false,
        },
        trClassFooter: {
            type: String,
            default: "",
        },
        moveTopPagination: {
            type: Boolean,
            default: false,
        },
        caching: {
            type: Boolean,
            default: false,
        },
        autoupdateEnabled: {
            type: Boolean,
            default: false,
        },
        autoupdateSeconds: {
            type: Number,
            default: 300,
        },
        autoupdatePosition: {
            type: String,
            default: "bottom",
        },
        infiniteScroll: {
            type: Boolean,
            default: false,
        },
        hover: {
            type: Boolean,
            default: true,
        },
        theadClass: {
            type: String,
            default: "",
        },
        tbClass: {
            type: String,
            default: "",
        },
        scrollToId: {
            type: String,
            default: "",
        },
        scrollMoveFirstLoad: {
            type: Boolean,
            default: false,
        }
    },
    data: function () {
        return {
            cfgdatepicker: {
                disableMobile: true,
                enableTime: false,
                altInput: true,
                altFormat: "d/m/y",
                dateFormat: "Y-m-d",
                minDate: null,
                maxDate: null,
                wrap: true,
                defaultDate: null,
                locale: {
                    firstDayOfWeek: 1,
                },
                mode: "single",
            },
            cfgrangepicker: {
                disableMobile: true,
                enableTime: false,
                altInput: true,
                altFormat: "d/m/y",
                dateFormat: "Y-m-d",
                minDate: null,
                maxDate: null,
                wrap: true,
                defaultDate: null,
                locale: {
                    firstDayOfWeek: 1,
                },
                mode: "range",
            },
            perPage: null,
            statusTable: null,
            totalRows: null,
            itemdata: [],
            sortBy: null,
            sortDesc: null,
            sortDirection: null,
            currentPage: null,
            pageOptions: null,
            visible: false,
            visibleAdvancedFilterPanel: false,
            timer: null,
            timer_enabled: false,
            timer_max: 300000,
            counter: null,
            counter_enabled: false,
            counter_max: (300000 / 1000),
            counter_value: 0,
            persistentItems: [],
            firstLoadScroll: this.scrollMoveFirstLoad
        };
    },
    beforeUnmount () {
        this.cancelAutoUpdate()
    },
    mounted() {
        this.perPage = this.perPageDefault;
        this.currentPage = this.currentPageDefault;
        this.statusTable = this.statusTableDefault;
        this.totalRows = this.totalRowsDefault;
        this.sortBy = this.sortByDefault;
        this.sortDesc = this.sortDescDefault;
        this.sortDirection = this.sortDirectionDefault;
        this.pageOptions = this.pageOptionsWithTotal;
        this.timer_max = (this.autoupdateSeconds * 1000);
        this.counter_max = this.autoupdateSeconds;
        this.addNullValueMultiSelect(); // add null value to multiselect values
        if (!this.pagination) {
            this.perPage = 0;
        }
        if (
            this.advancedFilters != undefined &&
            this.advancedFilters.length > 0
        ) {
            this.table_buttons.push({
                label: "Advanced filters",
                clicknobus: "toggleAdvancedFilters",
                class: "bg-light mr-2 mt-2",
                icon: "feather icon-sliders",
            });
        }
        // force calculate total rows on server side
        if (!this.serverside) {
            this.totalRows = _.size(this.items);
        }
        this.checkLocalStorage()
    },
    beforeDestroy() {
        /* Clean up just to be sure */
    },
    computed: {
        pageOptionsWithTotal: function () {
            let r = [];
            for (let n = 0; n < _.size(this.pageOptionsDefault); n++) {
                r.push({
                    value: this.pageOptionsDefault[n].value,
                    text:
                        this.pageOptionsDefault[n].text +
                        (!this.isEmptyValue(this.totalRows)
                            ? " / " + this.totalRows
                            : ""),
                });
            }
            return r;
        },
        visibleFields: function () {
            return this.fields.filter(function (field) {
                return !(field.visible != undefined && field.visible == false);
            });
        },
        filterEnabled: function () {
            return this.filters.length > 0 ? true : false;
        },
        tableButtonsEnabled: function () {
            return this.table_buttons.length > 0 ? true : false;
        },
        iconButtonsEnabled: function () {
            return this.icon_buttons.length > 0 ? true : false;
        },
        simpleFilters: function () {
            return this.filters.filter(function (filter) {
                return filter.advanced == undefined || filter.advanced == false;
            });
        },
        advancedFilters: function () {
            return this.filters.filter(function (filter) {
                return filter.advanced != undefined && filter.advanced == true;
            });
        },
        /*appliedFilters: function () {
            let r = this.filters.filter(function (filter) {
                console.log(filter)
                console.log(filter.key)
                console.log(filter['advanced'])
                console.log(filter['search'])
                return (
                    filter.advanced != undefined &&
                    filter.advanced == true &&
                    filter.search != undefined &&
                    filter.search != null
                );
            });
            if (r != undefined && r.length > 0) {
                // iteract and search text info
                _.each(r, function (data, key) {
                    data.filtered_value = "";
                    if (data.search != null && data.search != undefined) {
                        if (data.type == "date") {
                            data.filtered_value = moment(data.search).format("DD/MM/YY");
                        } else if (data.type == "text") {
                            data.filtered_value = data.search;
                        } else if (
                            data.type == "select" &&
                            data.options != undefined &&
                            data.options.values != undefined &&
                            data.options.values.length > 0
                        ) {
                            _.each(data.options.values, function (row, key) {
                                if (row[data.options.key] == data.search) {
                                    data.filtered_value =
                                        row[data.options.label];
                                    return false;
                                }
                            });
                        } else if (
                            data.type == "multiselect" &&
                            data.search != undefined
                        ) {
                            if (data.options.multiple) {
                                _.each(data.search, function (row, key) {
                                    data.filtered_value +=
                                        row[data.options.label] + ", ";
                                });
                                if (!isEmptyValue(data.filtered_value)) {
                                    data.filtered_value =
                                        data.filtered_value.substr(
                                            0,
                                            data.filtered_value.length - 2
                                        );
                                }
                            } else {
                                if (isEmptyValue(data.search[0])) {
                                    data.filtered_value = data.search[data.options.label]
                                } else {
                                    data.filtered_value = data.search[0][data.options.label]
                                }
                            }
                        }
                    }
                });
            }
            return r;
        },*/
        counterMinutes() {
            let m = Math.floor(((this.counter_max - this.counter_value) % (60 * 60)) / (60))
            if (m < 0) {
                return '00'
            } else if (m < 10) {
                return '0' + m
            }
            return m
        },
        counterSeconds() {
            let s = Math.floor(((this.counter_max - this.counter_value) % (60)))
            if (s < 0) {
                return '00'
            } else if (s < 10) {
                return '0' + s
            }
            return s
        },
        titleTimer() {
            let m = Math.floor(this.autoupdateSeconds / 60)
            let s = Math.floor(this.autoupdateSeconds % 60)
            let r = 'Every '
            if (m > 0) {
                if (m > 1) {
                    r += m + ' minutes '
                } else {
                    r += m + ' minute '
                }
                if (s > 0) {
                    r += ' and '
                }
            }
            if (s > 0) {
                if (s > 1) {
                    r += s + ' seconds'
                } else {
                    r += s + ' second'
                }
            }
            return r
        },
        visibleLoadMore: function () {
            if (this.totalRows > 0 && this.totalRows > _.size(this.persistentItems) && _.size(this.persistentItems) > 0) {
                return true
            }
            return false
        },
        hasLegends: function () {
            for (let x = 0; x < _.size(this.fields); x++) {
                if (!isEmptyValue(this.fields[x].legend)) {
                    return true
                }
            }
            return false
        },
    },
    methods: {
        appliedFilters () {
            let r = this.filters.filter(function (filter) {
                return (
                    filter.advanced != undefined &&
                    filter.advanced == true &&
                    filter.search != undefined &&
                    filter.search != null
                );
            });
            if (r != undefined && r.length > 0) {
                // iteract and search text info
                _.each(r, function (data, key) {
                    data.filtered_value = "";
                    if (data.search != null && data.search != undefined) {
                        if (data.type == "date") {
                            data.filtered_value = moment(data.search).format("DD/MM/YY");
                        } else if (data.type == "text") {
                            data.filtered_value = data.search;
                        } else if (
                            data.type == "select" &&
                            data.options != undefined &&
                            data.options.values != undefined &&
                            data.options.values.length > 0
                        ) {
                            _.each(data.options.values, function (row, key) {
                                if (row[data.options.key] == data.search) {
                                    data.filtered_value =
                                        row[data.options.label];
                                    return false;
                                }
                            });
                        } else if (
                            data.type == "multiselect" &&
                            data.search != undefined
                        ) {
                            if (data.options.multiple) {
                                _.each(data.search, function (row, key) {
                                    data.filtered_value +=
                                        row[data.options.label] + ", ";
                                });
                                if (!isEmptyValue(data.filtered_value)) {
                                    data.filtered_value =
                                        data.filtered_value.substr(
                                            0,
                                            data.filtered_value.length - 2
                                        );
                                }
                            } else {
                                if (isEmptyValue(data.search[0])) {
                                    data.filtered_value = data.search[data.options.label]
                                } else {
                                    data.filtered_value = data.search[0][data.options.label]
                                }
                            }
                        }
                    }
                });
            }
            return r;
        },
        callToFunction(value, params) {
            bus.$emit(value, params)
        },
        addNullValueMultiSelect() {
            // add null value to multiselect values, if the null record does not exist
            _.each(this.filters, function (data, key) {
                if (
                    typeof data.type !== "undefined" &&
                    data.type == "multiselect"
                ) {
                    if (
                        data.options != undefined &&
                        data.options.values != undefined
                    ) {
                        if (data.options.values.length > 0) {
                            let first = data.options.values[0];
                            if (first[data.options.key] == null) {
                                data.search = first;
                            }
                        }
                    }
                }
            });
        },
        isEmptyValue(value) {
            if (value == undefined || value == "" || value == null) {
                return true;
            }
            return false;
        },
        refreshTable() {
            this.$root.$emit("bv::refresh::table", this.id);
        },
        unselectAllItemRows() {
            this.$refs["bTable" + this.id].clearSelected();
        },
        selectAllItemRows() {
            this.$refs["bTable" + this.id].selectAllRows();
        },
        selectItemRow(index, rows) {
            let row = -1;
            if (!this.serverside) {
                row = this.items.findIndex((x) => x[this.primaryKey] === index);
            } else {
                row = this.itemdata.findIndex(
                    (x) => x[this.primaryKey] === index
                );
            }
            this.$refs["bTable" + this.id].selectRow(row);
        },
        unselectItemRow(index) {
            let row = -1;
            if (!this.serverside) {
                row = this.items.findIndex((x) => x[this.primaryKey] === index);
            } else {
                row = this.itemdata.findIndex(
                    (x) => x[this.primaryKey] === index
                );
            }
            this.$refs["bTable" + this.id].unselectRow(row);
        },
        onScroll() {
            if (this.infiniteScroll && !this.statusTable) {
                if (this.totalRows > 0 && this.totalRows > _.size(this.persistentItems)) {
                    this.currentPage = this.currentPage + 1
                    this.refreshTable()
                }
            }
        },
        async myProvider(ctx) {
            ctx.filter = this.getSearchFields();
            try {
                if (this.infiniteScroll) {
                    // no more data
                    if (this.totalRows > 0 && this.totalRows == _.size(this.persistentItems)) {
                        return this.persistentItems
                    }
                }
                let xdata = [];
                if (_.size(this.otherfilters) > 0) {
                    _.each(this.otherfilters, function (value, key) {
                        ctx.filter.push({
                            field: key,
                            type: "text",
                            value: value,
                        });
                    });
                }
                xdata.push({ name: "params", value: ctx });
                _.each(this.otherparams, function (value, key) {
                    xdata.push({ name: key, value: value });
                });
                const objdata = _.mapValues(_.keyBy(xdata, "name"), "value");
                const response = await axios({
                    method: this.method,
                    url: this.url,
                    data: objdata,
                });
                this.totalRows = response.data.totalRows
                let new_dataset = null
                if (this.infiniteScroll) {
                    this.persistentItems = this.persistentItems.concat(response.data.rows)
                    if (this.callbackProvider) {
                        this.callbackProvider(this.persistentItems)
                    }
                    if (this.selectable || this.exportable) {
                        Vue.set(this, "itemdata", this.persistentItems)
                    }
                    new_dataset = this.persistentItems
                } else {
                    if (this.callbackProvider) {
                        this.callbackProvider(response.data.rows)
                    }
                    if (this.selectable || this.exportable) {
                        Vue.set(this, "itemdata", response.data.rows)
                    }
                    if (this.moveTopPagination && this.firstLoadScroll) {
                        window.scroll(0,findPos(document.getElementById(document.getElementById(this.scrollToId) ? this.scrollToId : this.id)) - 160)
                    }
                    this.firstLoadScroll = true;
                    new_dataset = response.data.rows
                }

                this.saveOnLocalStorage()
                return new_dataset
            } catch (error) {
                this.totalRows = 0;
                return [];
            }
        },
        resetAdvancedFilters(refresh = true) {
            let changed = false;
            if (refresh) {
                this.clearLocalStorage()
            }
            for (let n = 0; n < this.filters.length; n++) {
                if (
                    this.filters[n].advanced != undefined &&
                    this.filters[n].advanced == true
                ) {
                    if (!this.isEmptyValue(this.filters[n].search)) {
                        if (!this.isEmptyValue(this.filters[n].default)) {
                            this.filters[n].search = this.filters[n].default;
                        } else {
                            this.filters[n].search = null;
                        }
                        changed = true;
                    }
                }
            }
            if (changed && refresh) this.refreshTable();
        },
        resetSimpleFilters(refresh = true) {
            let changed = false;
            this.clearLocalStorage()
            for (let n = 0; n < this.filters.length; n++) {
                if (this.filters[n].advanced == undefined) {
                    if (!this.isEmptyValue(this.filters[n].search)) {
                        if (!this.isEmptyValue(this.filters[n].default)) {
                            this.filters[n].search = this.filters[n].default;
                        } else {
                            this.filters[n].search = null;
                        }
                        changed = true;
                    }
                }
            }
            this.resetAdvancedFilters(false);
            if (changed && refresh) this.refreshTable();
        },
        removeFilter(filter) {
            filter.search = null;
            this.refreshTable();
        },
        toggleAdvancedFilters() {
            this.visibleAdvancedFilterPanel = !this.visibleAdvancedFilterPanel;
        },
        callToNoBusFunction(f) {
            this[f]();
        },
        checkLocalStorage() {
            if (this.caching) {
                let has_params = false
                let key = null
                let query = getQueryParameters()
                if (!this.isEmptyValue(query)) {
                    for (let x = 0; x < _.size(Object.keys(query)); x++) {
                        key = Object.keys(query)[x]
                        if (!isEmptyValue(key) && key != 'adv_s') {
                            has_params = true
                        }
                    }
                }
                //if (has_params) {
                    //this.clearLocalStorage()
                //} else {
                    /*if (!this.isEmptyValue(localStorage.getItem(this.id + '_currentPage')) && this.pagination && this.totalRows > 0 && this.perPage > 0) {
                        this.currentPage = localStorage.getItem(this.id + '_currentPage')
                    } else {
                        this.currentPage = this.currentPageDefault;
                    }
                    if (!this.isEmptyValue(localStorage.getItem(this.id + '_perPage')) && this.pagination) {
                        this.perPage = localStorage.getItem(this.id + '_perPage')
                    } else {
                        this.currentPage = this.perPageDefault;
                    }*/
                    const _this = this
                    for (let n = 0; n < _.size(this.filters); n++) {
                        if (!this.isEmptyValue(localStorage.getItem(this.id + '_filter_' + this.filters[n].key))) {
                            //if (typeof this.filters[n].search === 'object') {
                                if (this.filters[n].type == "multiselect") {
                                    if (this.filters[n].options.multiple) {
                                        this.filters[n].search = []
                                        let arr = localStorage.getItem(this.id + '_filter_' + this.filters[n].key).split(',')
                                        let match = null
                                        for (let i = 0; i < _.size(arr); i++) {
                                            match = _.filter(this.filters[n].options.values, function (r) {
                                                return r[_this.filters[n].options.key] == arr[i]
                                            })
                                            if (!isEmptyValue(match) && _.size(match) == 1) {
                                                this.filters[n].search.push(match[0])
                                            }
                                        }
                                    } else {
                                        // find object
                                        let match = _.filter(this.filters[n].options.values, function (r) {
                                            return r[_this.filters[n].options.key] == localStorage.getItem(_this.id + '_filter_' + _this.filters[n].key)
                                        })
                                        if (!isEmptyValue(match)) {
                                            if (!isEmptyValue(match[0])) {
                                                this.filters[n].search = match[0]
                                            } else {
                                                this.filters[n].search = match
                                            }
                                        }
                                    }
                                } else if (this.filters[n].type == "select") {
                                    this.filters[n].search = localStorage.getItem(this.id + '_filter_' + this.filters[n].key)
                                } else if (this.filters[n].type == "text") {
                                    this.filters[n].search = localStorage.getItem(this.id + '_filter_' + this.filters[n].key)
                                }
                            /*} else {
                                this.filters[n].search = localStorage.getItem(this.id + '_filter_' + this.filters[n].key)
                            }*/
                        }
                    }
                //}
            }
        },
        saveOnLocalStorage() {
            if (this.caching) {
                /*if (this.pagination && this.totalRows > 0 && this.perPage > 0) {
                    localStorage.setItem(this.id + '_currentPage', this.currentPage)
                } else {
                    localStorage.removeItem(this.id + '_currentPage')
                }
                if (this.pagination) {
                    localStorage.setItem(this.id + '_perPage', this.perPage)
                } else {
                    localStorage.removeItem(this.id + '_perPage')
                }*/
                for (let n = 0; n < _.size(this.filters); n++) {
                    if (!this.isEmptyValue(this.filters[n].search)) {
                        if (typeof this.filters[n].search === 'object') {
                            if (this.filters[n].type == "multiselect") {
                                if (this.filters[n].options.multiple) {
                                    let multiple = []
                                    for (let i = 0; i < _.size(this.filters[n].search); i++) {
                                        multiple.push(this.filters[n].search[i][this.filters[n].options.key]);
                                    }
                                    localStorage.setItem(this.id + '_filter_' + this.filters[n].key, multiple)
                                } else {
                                    localStorage.setItem(this.id + '_filter_' + this.filters[n].key, this.filters[n].search[this.filters[n].options.key])
                                }
                            } else if (this.filters[n].type == "select") {
                                localStorage.setItem(this.id + '_filter_' + this.filters[n].key, this.filters[n].search)
                            }
                        } else {
                            localStorage.setItem(this.id + '_filter_' + this.filters[n].key, this.filters[n].search)
                        }
                    } else {
                        localStorage.removeItem(this.id + '_filter_' + this.filters[n].key)
                    }
                }
            }
        },
        clearLocalStorage() {
            for (let n = 0; n < _.size(this.filters); n++) {
                localStorage.removeItem(this.id + '_filter_' + this.filters[n].key)
            }
        },
        getSearchFields() {
            let r = [];
            _.each(this.filters, function (data, key) {
                if (typeof data.search !== "undefined") {
                    if (
                        typeof data.search === "object" &&
                        data.search != undefined
                    ) {
                        if (data.type == "multiselect") {
                            let options = [];
                            if (
                                data.options.multiple != undefined &&
                                data.options.multiple == true
                            ) {
                                _.each(data.search, function (item, itemKey) {
                                    options.push(item[data.options.key]);
                                });
                            } else {
                                options = data.search[data.options.key];
                            }
                            r.push({
                                field:
                                    data.datafield != undefined &&
                                    data.datafield != ""
                                        ? data.datafield
                                        : data.key,
                                value: options,
                                type: data.type
                            });
                        } else {
                            if (isEmptyValue(data.search[0])) {
                                r.push({
                                    field:
                                        data.datafield != undefined &&
                                        data.datafield != ""
                                            ? data.datafield
                                            : data.key,
                                    value: data.search[data.options.key],
                                    type:
                                        data.type != undefined && data.type != ""
                                            ? data.type
                                            : "text",
                                    subtype:
                                        data.subtype != undefined && data.subtype != ""
                                            ? data.subtype
                                            : null,
                                });
                            } else {
                                r.push({
                                    field:
                                        data.datafield != undefined &&
                                        data.datafield != ""
                                            ? data.datafield
                                            : data.key,
                                    value: data.search[0][data.options.key],
                                    type:
                                        data.type != undefined && data.type != ""
                                            ? data.type
                                            : "text",
                                    subtype:
                                        data.subtype != undefined && data.subtype != ""
                                            ? data.subtype
                                            : null,
                                });
                            }
                        }
                    } else {
                        r.push({
                            field:
                                data.datafield != undefined &&
                                data.datafield != ""
                                    ? data.datafield
                                    : data.key,
                            value: data.search,
                            type:
                                data.type != undefined && data.type != ""
                                    ? data.type
                                    : "text",
                            subtype:
                                data.subtype != undefined && data.subtype != ""
                                    ? data.subtype
                                    : null,
                        });
                    }
                }
            });
            return r;
        },
        cancelAutoUpdate() {
            if (!this.isEmptyValue(this.timer)) {
                this.counter_enabled = false
                clearInterval(this.counter)
                this.counter_value = 0
                this.timer_enabled = false
                clearInterval(this.timer)
            }
        },
        activeAutoUpdate(miliseconds) {
            const _this = this
            this.timer = setInterval(function () {
                _this.counter_value = 0
                _this.refreshTable()
            }, miliseconds)
            this.timer_enabled = true
            this.counter_value = 0
            this.counter = setInterval(function () {
                if (_this.counter_value < _this.counter_max) {
                    _this.counter_value ++
                } else {
                    _this.counter_value = 0
                }
            }, 1000)
            this.counter_enabled = true
        },
        toggleTimer() {
            if (this.timer_enabled) {
                this.cancelAutoUpdate()
            } else {
                this.activeAutoUpdate(this.timer_max)
            }
        },
    },
    watch: {
        // transform data
        totalRows: {
            handler: function (newValue) {
                this.pageOptions = this.pageOptionsWithTotal;
                if (this.forceVisible === true) {
                    this.visible = true;
                } else {
                    this.visible =
                        this.totalRows > 0
                            ? true
                            : this.filterEnabled ||
                              this.tableButtonsEnabled ||
                              this.iconButtonsEnabled
                            ? true
                            : false;
                }
            },
        },
        items: {
            handler: function (newValue) {
                if (!this.serverside) {
                    this.totalRows = this.items.length;
                    if (this.selectable || this.exportable) {
                        Vue.set(this, "itemdata", this.items);
                    }
                }
            },
        },
    },
};
</script>
