<template>
  <div class="profile_media">
    <!-- COVER PHOTO -->
    <div class="cover_photo">
      <b-overlay variant="transparent" :show="overlay_cover">
        <div id="dz_cover_photo" class="text-center">
          <b-img
            class="cover_photo_img"
            ref="dz_cover_photo_img"
            center
            :src="srcCover"
            :alt="profileName"
            :title="profileName"
          >
          </b-img>
          <b-button
            id="upload-cover"
            v-on:click="uploadCover()"
            :disabled="dz_sending == true"
            class="btn-coverupload right"
          >
            <i class="fa fa-camera"></i> Upload cover photo
          </b-button>
          <dropzone
            ref="dzCover"
            id="dz_cover"
            :options="dzOptCover"
            :include-styling="false"
            v-on:vdropzone-complete="complete"
            v-on:vdropzone-error="error"
            v-on:vdropzone-sending="sending"
            v-on:vdropzone-upload-progress="progress"
            v-on:vdropzone-thumbnail="thumbnailCover"
          >
          </dropzone>
        </div>
      </b-overlay>
    </div>
    <!-- END COVER PHOTO -->
    <!-- IMAGE PROFILE PHOTO -->
    <div class="profile_img">
      <b-overlay
        variant="transparent"
        :show="overlay_profile"
        class="d-inline-block"
        rounded="circle"
      >
        <div id="dz_profile_photo" class="avatar-view">
          <b-img
            class="profile_photo_img profile_img"
            fluid
            ref="dz_profile_photo"
            thumbnail
            v-bind="{ width: 200 }"
            rounded="circle"
            :src="srcProfile"
            :alt="profileName"
            :title="profileName"
          >
          </b-img>
          <b-button
            id="upload-profile"
            v-on:click="uploadPhoto()"
            :disabled="dz_sending == true"
            class="btn-upload"
          >
            <i class="fa fa-camera"></i>
          </b-button>
          <dropzone
            ref="dzProfile"
            id="dz_profile"
            :options="dzOptProfile"
            :include-styling="false"
            v-on:vdropzone-complete="complete"
            v-on:vdropzone-error="error"
            v-on:vdropzone-sending="sending"
            v-on:vdropzone-upload-progress="progress"
            v-on:vdropzone-thumbnail="thumbnailProfile"
          >
          </dropzone>
        </div>
      </b-overlay>
    </div>
    <!-- END IMAGE PROFILE PHOTO -->
    <!-- @include('profile_media._modal_cropper')-->
    <!-- MODAL CROPPER -->
    <b-modal
      id="modal_cropper"
      ref="modalCropper"
      :no-close-on-esc="true"
      size="fullscreen"
      scrollable
      class="modal fade"
      no-fade
      no-enforce-focus
    >
      <template v-slot:modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h4 class="modal-title" id="modalCropperLabel">
          <span class="m-r-10">Crop image</span>
        </h4>
        <b-button
          type="button"
          class="close"
          size="lg"
          data-dismiss="modal"
          aria-label="Close"
          @click="close()"
        >
          <span aria-hidden="true">&times;</span>
        </b-button>
      </template>
      <template v-slot:default="{ hide }">
        <div class="image-container text-center">
          <b-row>
            <b-col cols="12">
              <b-img
                :src="srcCropper"
                class="img_cropper"
                ref="imgCropper"
                id="crop_image"
                center
                fluid
              ></b-img>
            </b-col>
          </b-row>
        </div>
      </template>
      <template v-slot:modal-footer="{ close }">
        <b-row class="w-100">
          <b-col class="text-right">
            <b-button type="button" class="crop-upload btn-xw" variant="primary"
              >Upload</b-button
            >
            <b-button
              type="button"
              variant="outline-primary"
              class="btn-xw"
              size="md"
              data-dismiss="modal"
              @click="close()"
              >Close</b-button
            >
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- END MODAL CROPPER -->
  </div>
</template>

<script src="js/cropper.js"></script>
<script>
// Vue Dropzone & cropper
import Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";

Dropzone.autoDiscover = false;

var cachedFilename;
var cropper;

export default {
  name: "profile-media",
  components: {
    dropzone: Dropzone,
  },
  props: {
    srcCover: {
      type: String,
      default: "",
    },
    srcProfile: {
      type: String,
      default: "",
    },
    profileName: {
      type: String,
      default: "",
    },
    urlUploadProfile: {
      type: String,
      default: "",
    },
    urlUploadCover: {
      type: String,
      default: "",
    },
    msgErrorUpload: {
      type: String,
      default: "",
    },
    msgSuccessUpload: {
      type: String,
      default: "",
    },
    updateProfileImage: {
      type: String,
      default: "false",
    },
  },
  data: function () {
    return {
      overlay_cover: false,
      overlay_profile: false,
      dz_sending: false,
      srcCropper: "",
      progress_value: 0,
      dzOptProfile: {
        url: this.urlUploadProfile,
        uploadMultiple: false,
        headers: { "X-CSRF-Token": CSRF_TOKEN },
        previewTemplate: "<div></div>",
        previewsContainer: ".profile_photo_img",
        paramName: "file",
        acceptedMimeTypes: "image/*",
        autoProcessQueue: false,
        cropperRatio: "1",
        cropperMinWidth: "200",
        cropperMinHeight: "200",
        cropperMaxWidth: "400",
        cropperMaxHeight: "400",
        clickable: true,
      },
      // Config vue-dropzone for image cover
      dzOptCover: {
        url: this.urlUploadCover,
        uploadMultiple: false,
        headers: { "X-CSRF-Token": CSRF_TOKEN },
        previewTemplate: "<div></div>",
        previewsContainer: ".cover_photo_img",
        paramName: "file",
        acceptedMimeTypes: "image/*",
        autoProcessQueue: false,
        cropperRatio: "5.1",
        cropperMinWidth: "1200",
        cropperMinHeight: "234",
        cropperMaxWidth: "1800",
        cropperMaxHeight: "350",
      },
    };
  },
  mounted: function () {
    // Register the shown event on modal cropper
    this.progress_value = 0;
    this.$root.$on("bv::modal::shown", (bvEvent, modalId) => {
      if (modalId == 'modal_cropper') {
        this.onShowModalCropper(bvEvent, modalId);
      }
    });
  },
  methods: {
    uploadPhoto() {
      this.$refs.dzProfile.$el.click();
    },
    uploadCover() {
      this.$refs.dzCover.$el.click();
    },
    onShowModalCropper(bvEvent, modalId) {
      var upload_btn = $("#" + modalId).find(".crop-upload");
      cropper = this.createCropper(
        this.$refs.imgCropper,
        this.dz_name == "profile" ? this.$refs.dzProfile : this.$refs.dzCover,
        upload_btn
      );
    },
    createCropper(image, myDropzone, upload_btn) {
      var maxAspectRatio = myDropzone.options.cropperRatio;
      cropper = new Cropper(image, {
        viewMode: 2,
        aspectRatio: maxAspectRatio,
        zoomable: true,
        dragMode: "move",
        restore: false,
        guides: true,
        center: true,
        highlight: true,
        cropBoxMovable: true,
        cropBoxResizable: true,
        toggleDragModeOnDblclick: false,
        responsive: true,
      });
      const _this = this;
      upload_btn.on("click", function () {
        // get cropped image data
        var blob = cropper
          .getCroppedCanvas({
            height: myDropzone.options.cropperMaxHeight,
            minHeight: myDropzone.options.cropperMaxHeight,
          })
          .toDataURL();
        // transform it to Blob object
        var newFile = _this.dataURItoBlob(blob);
        // set 'cropped to true' (so that we don't get to that listener again)
        newFile.cropped = true;
        // assign original filename
        newFile.name = cachedFilename;
        // add cropped file to dropzone
        myDropzone.addFile(newFile);
        // upload cropped file with dropzone
        myDropzone.processQueue();
        _this.$refs.modalCropper.hide();
      });
      return cropper;
    },
    complete(response) {
      var j, len, ref, thumbnailElement, previewsContainer, xhr, data;
      var myDropzone =
        this.dz_name == "profile" ? this.$refs.dzProfile : this.$refs.dzCover;
      if (response && response.status == "success") {
        if (this.dz_name == "profile") {
          data = { name: response.name, field: "photo" };
        } else {
          data = { name: response.name, field: "cover" };
        }
        bus.$emit("updateModel", data);

        previewsContainer = myDropzone.dropzone.options.previewsContainer;
        if (previewsContainer) {
          if (this.dz_name == "profile") {
            this.overlay_profile = true;
          } else {
            this.overlay_cover = true;
          }
          ref = document.querySelectorAll(previewsContainer);
          ShowPNotifySuccess(this.msgSuccessUpload);
          setTimeout(
            (function (_this) {
              return function () {
                for (j = 0, len = ref.length; j < len; j++) {
                  thumbnailElement = ref[j];
                  thumbnailElement.alt = response.name;
                  thumbnailElement.src = response.dataURL;
                }
                if (_this.updateProfileImage == true || _this.updateProfileImage == "true") {
                  if (_this.dz_name == 'profile') {
                    ref = document.querySelectorAll(".profile_photo");

                    for (j = 0, len = ref.length; j < len; j++) {
                        thumbnailElement = ref[j];
                        thumbnailElement.alt = response.name;
                        thumbnailElement.src = response.dataURL;
                    }
                  }
                }
                _this.overlay_profile = false;
                _this.overlay_cover = false;
                _this.dz_sending = false;
              };
            })(this),
            500
          );
        }
      }
    },
    error(file, message, xhr) {
      this.dz_sending = false;
      ShowPNotifyError(this.msgErrorUpload + message.errors);
    },
    sending(file, xhr, formData) {
      this.dz_sending = true;
    },
    progress(file, progress, bytesSent) {
      this.progress_value = progress;
    },
    thumbnailProfile(file, dataUrl) {
      this.dz_name = "profile";
      this.cropperProcess(file, dataUrl, this.$refs.dzProfile);
    },
    thumbnailCover(file, dataUrl) {
      this.dz_name = "cover";
      this.cropperProcess(file, dataUrl, this.$refs.dzCover);
    },
    cropperProcess(file, dataUrl, myDropzone) {
      // ignore files which were already cropped and re-rendered
      // to prevent infinite loop
      if (file.cropped) {
        return;
      }
      if (file && file.width < myDropzone.options.cropperMinWidth) {
        // validate width to prevent too small files to be uploaded
        ShowPNotifyError(
          "The image is too small. Please, try uploading a larger image again. Minimum requirements: " +
            myDropzone.options.cropperMinWidth +
            "px x " +
            myDropzone.options.cropperMinHeight +
            "px."
        );
        return;
      }
      // cache filename to re-assign it to cropped file
      cachedFilename = file.name;
      // remove not cropped file from dropzone (we will replace it later)
      myDropzone.removeFile(file);

      this.$bvModal.show("modal_cropper");
      //this.$refs.modalCropper.show();
      const _this = this;
      // initialize FileReader which reads uploaded file
      var reader = new FileReader();
      reader.onloadend = function () {
        // add uploaded and read image to modal
        _this.srcCropper = reader.result;
      };

      // read uploaded file (triggers code above)
      reader.readAsDataURL(file);
    },
    // transform cropper dataURI output to a Blob which Dropzone accepts
    dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: "image/jpeg" });
    },
  },
};
</script>
