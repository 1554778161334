<template>
    <div v-if="events" class="d-flex flex-wrap col-12">
        <div class="col-12 d-flex flex-wrap" v-for="row in events">
            <div :class="columnClass" v-for="event in row" :key="event.id">
                <b-card class="card-event-times m-b-0 col-12 h-100">
                    <b-card-text class="mt-4">
                        <a v-bind:href="event.publicUrl">
                            <!-- Title -->
                            <b-row align-v="center">
                                <b-col align-self="start" cols="12">
                                    <span class="f-w-7 font-24 mr-2" v-html="htmlLimit(event.name, 25)"></span>
                                    <template v-if="event.items_count>0">
                                        <span class="badge badge-success">
                                            {{ event.items_count }}
                                        </span>
                                    </template>
                                </b-col>
                            </b-row>
                            <b-row align-v="center">
                                <b-col>
                                    <p class="font-20 f-w-5">
                                        <!-- {{ event.venue_name }} -->
                                        <!-- {{ getSponsors(event) }} -->
                                    </p>
                                    <p v-if="!isEmptyValue(event.disciplines)" class="font-14 f-w-6 d-flex align-items-center">
                                        <i class="font-23 ph-duotone mr-2 ph ph-trophy"></i> <span v-html="htmlLimit(event.disciplines, 25)"></span>
                                    </p>
                                    <p v-else class="font-14 f-w-6 d-flex align-items-center">
                                        &nbsp;
                                    </p>
                                </b-col>
                            </b-row>
                            <!-- Content -->
                            <div>
                                <hr>
                                <b-row class="mt-3" align-v="start">
                                    <b-col class="mb-3" :sm="cardColSm" :md="cardColMd" :lg="cardColLg" align-self="start">
                                        <b-row>
                                            <b-col cols="1">
                                                <i class="font-26 ph ph-calendar"></i>
                                            </b-col>
                                            <b-col class="font-secondary f-w-7 font-20">
                                                Event dates
                                            </b-col>
                                        </b-row>
                                        <template v-if="event.no_dates == 1">
                                            <b-row class="py-3">
                                                <b-col cols="1">
                                                </b-col>
                                                <b-col>
                                                    <b-row>
                                                        <b-col>
                                                            <span class="f-w-7">
                                                                Dates do not apply
                                                            </span>
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                        </template>
                                        <template v-else>
                                            <b-row class="min-md-border-right">
                                                <b-col>
                                                    <b-row>
                                                        <b-col class="f-w-7" cols="5">
                                                            {{ formatDate(event.start) }}
                                                        </b-col>
                                                        <b-col cols="2" class="px-0">
                                                            <hr class="event-line-range-datetime my-2">
                                                        </b-col>
                                                        <b-col class="f-w-7" cols="5">
                                                            {{ formatDate(event.end) }}
                                                        </b-col>
                                                    </b-row>

                                                    <b-row>
                                                        <b-col cols="5">
                                                            {{ formatTime(event.start) ? formatTime(event.start) : '&nbsp' }}
                                                        </b-col>
                                                        <b-col cols="2" class="px-0">
                                                        </b-col>
                                                        <b-col cols="5">
                                                            {{ formatTime(event.end) ? formatTime(event.end) : '&nbsp' }}
                                                        </b-col>
                                                    </b-row>
                                                </b-col>
                                            </b-row>
                                        </template>
                                    </b-col>
                                    <!-- Booking Dates -->
                                    <b-col class="mb-3" :sm="cardColSm" :md="cardColMd" :lg="cardColLg" align-self="start">
                                        <b-row v-if="isEventPublished(event) && isCloseBooking(event)">
                                            <b-col cols="1">
                                                <i class="font-26 text-danger ph ph-ticket"></i>
                                            </b-col>
                                            <b-col class="font-secondary f-w-7 font-20 text-danger">
                                                Bookings closed
                                            </b-col>
                                        </b-row>
                                        <b-row v-else-if="isEventCancelled(event)">
                                            <b-col cols="1">
                                                <i class="font-26 text-danger ph ph-ticket"></i>
                                            </b-col>
                                            <b-col class="font-secondary f-w-7 font-20 text-danger">
                                                Bookings closed
                                            </b-col>
                                        </b-row>
                                        <b-row v-else>
                                            <b-col cols="1">
                                                <i class="font-26 ph ph-ticket"></i>
                                            </b-col>
                                            <b-col class="font-secondary f-w-7 font-20">
                                                Bookings
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col>
                                                <b-row>
                                                    <b-col class="f-w-7" cols="5">
                                                        {{ formatDate(event.start_entries) }}
                                                    </b-col>
                                                    <b-col cols="2" class="px-0">
                                                        <hr class="event-line-range-datetime my-2">
                                                    </b-col>
                                                    <b-col class="f-w-7" cols="5">
                                                        {{ formatDate(event.close_entries) }}
                                                    </b-col>
                                                </b-row>

                                                <b-row>
                                                    <b-col cols="5">
                                                        {{ formatTime(event.start_entries) }}
                                                    </b-col>
                                                    <b-col cols="2" class="px-0">
                                                    </b-col>
                                                    <b-col cols="5">
                                                        {{ formatTime(event.close_entries) }}
                                                    </b-col>
                                                </b-row>

                                                <b-row v-if="!isEmptyValue(event.late_entries)">
                                                    <b-col>
                                                        <span
                                                            class="text-danger block">Late bookings up to</span>
                                                        {{ formatTime(event.late_entries, "HH:mm a") }} on {{ formatTime(event.late_entries, "D MMM") }}
                                                    </b-col>
                                                </b-row>
                                                <b-row v-else>
                                                    <b-col>
                                                        <span class="text-danger block mt-3">
                                                            &nbsp;
                                                        </span>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col :sm="cardColSm" :md="cardColMd" :lg="cardColLg" align-self="center">
                                        <b-row align-self="center">
                                            <b-col cols="12">
                                                <b-row>
                                                    <b-col v-if="isEventCancelled(event)" class="px-0">
                                                        <b-button block variant="danger" class="font-weight-bold font-14" >CANCELLED</b-button>
                                                    </b-col>
                                                    <b-col v-else-if="isEventPublished(event) && !isCloseBooking(event)" class="px-0">
                                                        <h5 v-if="isOpeningSoon(event)">
                                                            <b-button block variant="info" class="font-weight-bold font-14">Opening soon</b-button>
                                                        </h5>
                                                        <h5 v-else-if="isItemSoldOut(event)">
                                                            <b-button block variant="danger" class="font-weight-bold font-14">Sold out</b-button>
                                                        </h5>
                                                        <h5 v-else-if="isItemClosed(event)">
                                                            <b-button block variant="danger" class="font-weight-bold font-14" >Closed</b-button>
                                                        </h5>
                                                        <h5 v-else>
                                                            <b-button block variant="lightsuccess text-primary" class="font-weight-bold font-14">Enter</b-button>
                                                        </h5>
                                                    </b-col>
                                                    <b-col v-else-if="isEventPublished(event) && isCloseBooking(event)" class="px-0">
                                                        <b-button block variant="danger" class="font-weight-bold font-14" >Closed</b-button>
                                                    </b-col>
                                                    <b-col v-else-if="isEventSoldOut(event)" class="px-0">
                                                        <b-button block variant="danger" class="font-weight-bold font-14">Sold out</b-button>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </div>
                        </a>
                        <b-row cols="12" align-h="end" class="mt-3">
                            <b-row align-h="center" class="px-0 col-12 col-md-auto mx-0">
                                <b-button v-if="event.time_published == 1" variant="lightgray" class="mx-1 col-5 col-md-auto font-weight-bold font-14" :href="event.publicTimesUrl">Start list</b-button>
                                <b-button v-if="event.has_published_results == 1" variant="dark" class="mx-1 mr-0 col-5 col-md-auto font-weight-bold font-14" :href="event.publicResultUrl">Results</b-button>
                            </b-row>
                        </b-row>
                    </b-card-text>
                </b-card>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "card-events",
    props: {
        dateTime: {
            type: String,
            require: true,
            default: '',
        },
        url: {
            type: String,
            require: true,
            default: "",
        },
        statusCancelled: {
            type: Number,
            require: true,
        },
        statusPublished: {
            type: Number,
            require: true,
        },
        statusSoldOut: {
            type: Number,
            require: true,
        },
        filters: {
            type: Array,
            require: false,
            default: function () {
                return [];
            },
        },
        rows: {
            type: Number,
            require: false,
            default: 2,
        },
        perPage: {
            type: Number,
            require: false,
            default: 6,
        },
        columnClass: {
            type: String,
            require: false,
            default: '',
        },
        cardColSm: {
            type: String,
            require: false,
            default: '12',
        },
        cardColMd: {
            type: String,
            require: false,
            default: '12',
        },
        cardColLg: {
            type: String,
            require: false,
            default: '12',
        },
    },
    data: function () {
        return {
            events: [],
        };
    },
    beforeUnmount () {
        
    },
    mounted() {
        if(this.url) {
            this.loadEvents();
        }
    },
    beforeDestroy() {
        
    },
    computed: {
        
    },
    methods: {
        formatRow() {
            let r = [];
            for(var i = 0; i < this.rows; i++) {
                r[i] = this.events.splice(0, (this.perPage/this.rows))
            }
            this.events = r;
        },
        async loadEvents() {
            const response = await axios.post(this.url, {
                params: {
                    filter: this.filters,
                    perPage: this.perPage,
                    sortBy: "start",
                    sortDesc: false,
                    currentPage: 1,
                }
            })
            if (response.data) {
                this.events = response.data.rows;
                this.formatRow();
            }
        },
        getSponsors(item) {
            let r = ''
            let l = _.size(item.sponsors)
            if (!this.isEmptyValue(item) && l > 0) {
                r = ' - '
                for (let i = 0; i < l; i++) {
                    if (i == (l-1)) {
                        r += item.sponsors[i].name
                    } else {
                        r += item.sponsors[i].name + ' / '
                    }
                }
                r = r.trim()
            }
            return r
        },
        getRealCloseEntriesDate(row) {
                if (!this.isEmptyValue(row.late_entries_date)) {
                    return row.late_entries_date
                }
                return row.close_entries
            },
            getRealCloseEntriesTime(row) {
                if (!this.isEmptyValue(row.late_entries_time)) {
                    return row.late_entries_time
                }
                return row.close_entries
            },
            isCloseBooking(item) {
                if (!isEmptyValue(item) && !isEmptyValue(item.allow_entries_until_end) && item.allow_entries_until_end == 1) {
                    return (moment(this.dateTime).isAfter(moment(item.end)))
                } else {
                    if (!isEmptyValue(item.late_entries)) {
                        return (moment(this.dateTime).isAfter(moment(item.late_entries)))
                    } else {
                        return (moment(this.dateTime).isAfter(moment(item.close_entries)))
                    }
                }
            },
            isEventCancelled(item) {
                var r = false
                if (!isEmptyValue(item) && item.event_status_id == this.statusCancelled) {
                    r = true
                }
                return r
            },
            isEventPublished(item) {
                var r = false
                if (!isEmptyValue(item) && item.event_status_id == this.statusPublished) {
                    r = true
                }
                return r
            },
            isOpeningSoon(item) {
                var r = false
                if (!isEmptyValue(item) && moment().isBefore(moment(item.start_entries))) {
                    r = true
                }
                return r
            },
            isEventSoldOut(item) {
                var r = false
                if (!isEmptyValue(item) && item.event_status_id == this.statusSoldOut) {
                    r = true
                }
                return r
            },
            getSponsors(item) {
                let r = ''
                let l = _.size(item.sponsors)
                if (!this.isEmptyValue(item) && l > 0) {
                    r = ' - '
                    for (let i = 0; i < l; i++) {
                        if (i == (l-1)) {
                            r += item.sponsors[i].name
                        } else {
                            r += item.sponsors[i].name + ' / '
                        }
                    }
                    r = r.trim()
                }
                return r
            },
            isItemSoldOut(item) {
                var r = false
                if(!isEmptyValue(item)) {
                    if (item.items_sold_out >= item.all_items) {
                        r = true
                    }
                }
                return r
            },
            isItemClosed(item) {
                var r = false
                if(!isEmptyValue(item)) {
                    if (item.items_closed >= item.all_items) {
                        r = true
                    }
                    if ((item.items_closed + item.items_sold_out) >= item.all_items) {
                        r = true
                    }
                }
                return r
            },


        // ****** Basic Function ********
        isEmptyValue(value) {
            if (value == undefined || value == '' || value == null) {
                return true
            }
            return false
        },
        getStateField(field, observer) {
            observer = this.$refs[observer]
            if (!isEmptyValue(observer) && !isEmptyValue(observer.errors)) {
                let err = observer.errors[field]
                if (_.size(err)>0) {
                    return true
                }
            }
            return false
        },
        getStringDate(value) {
            return getStringDate(value)
        },
        getStringDateTime(value) {
            return getStringDateTime(value)
        },
        getFormatDateJs() {
            return "DD/MM/YYYY"
        },
        getFormatTimeJs() {
            return "HH:mm"
        },
        getFormatDateTimeJs() {
            return "DD/MM/YY HH:mm"
        },
        formatDate(value, format = "ddd Do MMM 'YY") {
            if (!this.isEmptyValue(value) && !this.isEmptyValue(format)) {
                return moment(value).format(format)
            }
            return ''
        },
        formatTime (value, format = "HH:mm") {
            if (!this.isEmptyValue(value) && !this.isEmptyValue(format)) {
                let r = moment(value).format(format)
                if (r!='00:00') return r
            }
            return ''
        },
        formatDateTime (value, format = "ddd, DD MMM YYYY HH:mm") {
            if (!this.isEmptyValue(value) && !this.isEmptyValue(format)) {
                return moment(value).format(format)
            }
            return ''
        },
        htmlLimit(text, limit = 100) {
            if(text != null && text.length > limit){
                text = text.replace(/(<([^>]+)>)/gi, "").substring(0, limit)+'...';
            } else {
                text += ' ';
                let lm_text = (limit-text.length);
                for (let i = 0; i < lm_text; i++) {
                    text += '&nbsp';
                }
            }
            return text;
        }
    },
};
</script>
