<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import enLocale from '@fullcalendar/core/locales/en-gb'

export default {
    components: {
        FullCalendar // make the <FullCalendar> tag available
    },
    props: {
        calendarRef: {
            type: String,
            default: null,
        },
        eventColor: {
            type: String,
            default: '#007D00'
        },
        mmanager_id: {
            type: Number,
            default: null,
        },
        horse_id: {
            type: Number,
            default: null,
        },
        athlete_id: {
            type: Number,
            default: null,
        },
        url: {
            type: String,
            default: null,
        },
        mobile: {
            type: Boolean,
            default: true,
        },
        successCallback: {
            type: Function,
            default: null,
        },
        initialView: {
            type: String,
            default: 'dayGridMonth'
        },
        headerToolbarEnabled: {
            type: Boolean,
            default: true
        },
    },
    data: function () {
        return {
            calendarOptions: {
                selectable: true,
                plugins: [ interactionPlugin, dayGridPlugin, bootstrapPlugin ],
                views: {
                    dayGridMonth: {
                        titleFormat: { year: '2-digit', month: 'short', day: 'numeric' },
                        dayHeaders: true,
                        fixedWeekCount: false,
                    },
                    dayGridWeek: {
                        dayHeaders: true,
                        titleFormat: { year: '2-digit', month: 'short', day: 'numeric' },
                        dayHeaderFormat: { day: 'numeric'},
                    }
                },
                initialView: this.initialView,
                headerToolbar: this.getToolbarStatus(),
                buttonText: {
                    today: 'Today',
                    month: 'Month',
                    week: 'Week',
                    day: 'Day',
                    list: 'List',
                },
                themeSystem: 'bootstrap',
                contentHeight: 'auto',
                locale: enLocale,
                eventSources: [
                    {
                        url: this.url,
                        method: 'POST',
                        extraParams: {
                            'mmanager_id': this.mmanager_id,
                            'horse_id': this.horse_id,
                            'athlete_id': this.athlete_id,
                            '_token': $('meta[name="csrf-token"]').attr('content'),
                        }
                    }
                ],
                eventSourceSuccess: this.successCallback,
                eventClick: function(info) {
                    bus.$emit('eventClick', info)
                },
                dateClick: function(info) {
                    bus.$emit('dateClick', info)
                },
                datesSet: function (dateInfo) {
                    bus.$emit('datesSet', dateInfo)
                },
            },
        }
    },
    methods: {
        isEmptyValue(value) {
            return isEmptyValue(value)
        },
        getToolbarStatus() {
            if(this.headerToolbarEnabled) {
                return {
                    right: 'prev,today,next',
                    left: 'dayGridWeek,dayGridMonth',
                }
            } else {
                return false;
            }
        },
    }
}
</script>
<template>
  <FullCalendar :ref="calendarRef" :options="calendarOptions">
    <template v-slot:eventContent='arg'>
        <template v-if="mobile">
            <span v-b-tooltip.hover :title="arg.event.title" class="bullet-calendar" :style="(!isEmptyValue(arg.event.backgroundColor) ? 'background-color: ' + arg.event.backgroundColor + ';' : '')"></span>
        </template>
        <template v-else>
            <span v-b-tooltip.hover :title="arg.event.title" class="label-calendar" :style="(!isEmptyValue(arg.event.backgroundColor) ? 'background-color: ' + arg.event.backgroundColor + ';' : '')" >
                <template v-if="arg.event.title.length > 16">
                    {{ arg.event.title.substring(0, 13) + '...' }}
                </template>
                <template v-else>
                    {{ arg.event.title }}
                </template>
            </span>
        </template>
      </template>
  </FullCalendar>
</template>
